import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const QuantumPage = (props) => (
  <Layout>
    <SEO title="Quantum Page" />
    <Link className="back-link" to="/">Go back to the homepage</Link>
    <h1 style={{marginTop: `50px`, textAlign: 'center'}}>Quantum Claims Tool</h1>
    <Img fluid={props.data.imageQuantumHeader.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `0 auto`}}>
        <p>
            I was part of a team that created a comprehensive Quantum calculator tool for all types of occupational disease & personal injury claims.
        </p>
        <p>
            I was responsible for creating the UI, UX plus also implementing the front-end HTML and Sass code. I implemented a naming system based on BEM namimg conventions and a component architecture on the front-end based on the Atomic Design guidelines.
        </p>
        <p>
            The project also uses Vue JS on the front-end which I used to create reusable UI components.
        </p>
    </div>
    <Img fluid={props.data.imageQuantumWireframesFigma.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto`}}>
        <p>
            Figma was used to create wireframes and hi-fidelity designs that would form the basis of the product UI.
        </p>
    </div>
    <Img fluid={props.data.imageQuantumCharts.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto`}}>
        <p>
            Charting was used throughout to display data in a consumable way. The Javascript library ChartJS was used with the colours defined in the design phase.
        </p>
    </div>
    <Img fluid={props.data.imageQuantumCalcIcons.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto`}}>
        <p>
            The project needed a number of icons which I created in Illustrator and exported as SVG. Including this SVG code in the HTML markup of the product allowed for me to effect the icon properties using css and also create a project wide icon system that could be imported and referenced when needed.
        </p>
    </div>
    <Img fluid={props.data.imageQuantumCalcDesktop.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto`}}>
        <p>
            Layouts were deisgned so the user has a clean and descriptive UI to input data and receive their results. Screens adapt to smaller devices with the layout and it's components adapting to fit.
        </p>
    </div>
    <Img fluid={props.data.imageQuantumMobile.childImageSharp.fluid} />
    <h2 style={{marginTop: '40px'}}>View other pieces of work...</h2>
    <div className="work" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`, marginTop: `40px`}}>
        <a href="/tim-burton/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-2" style={{height: `300px`}}></div>
        </a>
        <a href="/icons/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-4" style={{height: `300px`}}></div>
        </a>
        <a href="/otis/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-5" style={{height: `300px`}}></div>
        </a>
    </div>
    <Link className="back-link" to="/" style={{marginTop: `20px`, display: `block`}}>Go back to the homepage</Link>
  </Layout>
)

export default QuantumPage

export const pageQuery = graphql`
  query {
    imageQuantumHeader: file(relativePath: { eq: "quantum-header.png" }) {
      ...fluidImage
    }
    imageQuantumWireframesFigma: file(relativePath: { eq: "quantum-wireframes-figma.png" }) {
      ...fluidImage
    }
    imageQuantumCharts: file(relativePath: { eq: "quantum-charts.png" }) {
      ...fluidImage
    }
    imageQuantumCalcIcons: file(relativePath: { eq: "quantum-calc-icons.png" }) {
      ...fluidImage
    }
    imageQuantumCalcDesktop: file(relativePath: { eq: "quantum-calc-desktop.png" }) {
      ...fluidImage
    }
    imageQuantumMobile: file(relativePath: { eq: "quantum-mobile.png" }) {
      ...fluidImage
    }
  }
`